import React, { Fragment } from 'react';
import './contact.sass';
import TextField from '@material-ui/core/es/TextField/TextField';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import xor from 'lodash/xor';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as yup from 'yup';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#009cf3',
    },
  },
  typography: {
    fontSize: 17,
    fontFamily: ['Lato', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"'],
  },
  overrides: {
    MuiInput: {
      root: {
        paddingBottom: '0.2rem',
        marginBottom: '0.3rem',
      },
      underline: {
        '&:hover:before': {
          borderBottomWidth: '1px !important',
        },
        '&:after': {
          borderBottom: '#009cf3 solid 1px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#000',
      },
      shrink: {
        transform: 'translate(0, -4px) scale(0.75)',
      },
      error: {
        color: '#333 !important',
      },
    },
    MuiIconButton: {
      root: {
        padding: '7px 12px',
      },
    },
  },
});

@observer
export default class ContactSection extends React.Component {
  @observable
  data = {
    name: '',
    email: '',
    phone: '',
    budget: '',
    timeframe: '',
    tellUsMore: '',
    projectInvolves: [],
  };

  @observable
  messageSent = false;

  @observable
  isValid = {
    name: true,
    email: true,
  };

  handleChange = name => event => {
    this.data[name] = event.target.value;
  };

  isFormValid = () => {
    return (
      yup
        .string()
        .required()
        .isValidSync(this.data.name) &&
      yup
        .string()
        .email()
        .required()
        .isValidSync(this.data.email)
    );
  };

  submit = e => {
    e.preventDefault();
    let formData = JSON.parse(JSON.stringify(this.data));
    formData.projectInvolves = formData.projectInvolves.join(';');
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({
        'form-name': 'enquiries',
        ...formData,
      }),
    })
      .then(() => console.log('Success!'))
      .catch(error => console.log('error', error));
    this.messageSent = true;
  };

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  render() {
    const {
      name,
      budget,
      email,
      phone,
      timeframe,
      projectInvolves,
      tellUsMore,
    } = this.data;
    let data = this.data;

    return (
      <section className="contact-section" id="contact">
        <div className="container">
          {this.messageSent && (
            <div className="thanks-wrapper">
              <div className="ico">
                <img src="/images/ico-check.svg" align="Thank you" />
              </div>
              <div className="title">Thank you for your enquiry!</div>
              <div className="subtitle">
                We'll get back to you within 2 business days.
              </div>
            </div>
          )}

          {!this.messageSent && (
            <Fragment>
              <div className="tell-us">
                Tell us about your project and how we could help you.
                <br />
                If you would like to speak to someone from our team, give us a
                call on{' '}
                <a href="tel://+61402694339" className="link">
                  +61 402 694 339
                </a>
              </div>

              <div className="form">
                <form
                  name="enquiries"
                  method="post"
                  data-netlify="true"
                  onSubmit={this.submit}
                >
                  <MuiThemeProvider theme={theme}>
                    <TextField
                      label="Your name"
                      fullWidth
                      margin={'normal'}
                      placeholder="Enter your name"
                      autoComplete="name"
                      name="name"
                      value={name}
                      onChange={this.handleChange('name')}
                      onBlur={() => {
                        this.isValid.name = yup
                          .string()
                          .required()
                          .isValidSync(data.name);
                      }}
                      error={!this.isValid.name}
                      helperText={!this.isValid.name && 'Enter your name'}
                    />
                    <TextField
                      label="Your email"
                      fullWidth
                      margin={'normal'}
                      placeholder="name@company.com"
                      type="email"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={this.handleChange('email')}
                      onBlur={() => {
                        this.isValid.email = yup
                          .string()
                          .email()
                          .required()
                          .isValidSync(data.email);
                      }}
                      error={!this.isValid.email}
                      helperText={
                        !this.isValid.email &&
                        'Please enter a valid email address'
                      }
                    />
                    <TextField
                      label="Contact number"
                      fullWidth
                      margin={'normal'}
                      placeholder="(optional)"
                      autoComplete="tel"
                      name="phone"
                      value={phone}
                      onChange={this.handleChange('phone')}
                    />
                    <div className="columns field is-gapless">
                      <div className="column label">
                        <div>Your project involves:</div>
                        <div className="note">Select all that apply</div>
                      </div>
                      <div className="column">
                        <FormGroup classes={{ root: 'radioRoot' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={projectInvolves.includes('website')}
                                onChange={e => {
                                  data.projectInvolves = xor(projectInvolves, [
                                    e.target.value,
                                  ]);
                                }}
                                name="projectInvolves"
                                value="website"
                              />
                            }
                            label="Website / Web app"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={projectInvolves.includes(
                                  'mobile_app/wearable'
                                )}
                                onChange={e => {
                                  data.projectInvolves = xor(projectInvolves, [
                                    e.target.value,
                                  ]);
                                }}
                                name="projectInvolves"
                                value="mobile_app/wearable"
                              />
                            }
                            label="Mobile / Wearable app"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={projectInvolves.includes(
                                  'smart_assistant'
                                )}
                                onChange={e => {
                                  data.projectInvolves = xor(projectInvolves, [
                                    e.target.value,
                                  ]);
                                }}
                                name="projectInvolves"
                                value="smart_assistant"
                              />
                            }
                            label="Smart assistant"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={projectInvolves.includes(
                                  'api/integration'
                                )}
                                onChange={e => {
                                  data.projectInvolves = xor(projectInvolves, [
                                    e.target.value,
                                  ]);
                                }}
                                name="projectInvolves"
                                value="api/integration"
                              />
                            }
                            label="API / System Integration"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={projectInvolves.includes('content')}
                                onChange={e => {
                                  data.projectInvolves = xor(projectInvolves, [
                                    e.target.value,
                                  ]);
                                }}
                                name="projectInvolves"
                                value="content"
                              />
                            }
                            label="Content / Media (CMS)"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={projectInvolves.includes('ux_ui')}
                                onChange={e => {
                                  data.projectInvolves = xor(projectInvolves, [
                                    e.target.value,
                                  ]);
                                }}
                                name="projectInvolves"
                                value="ux_ui"
                              />
                            }
                            label="UX / UI"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={projectInvolves.includes('blockchain')}
                                onChange={e => {
                                  data.projectInvolves = xor(projectInvolves, [
                                    e.target.value,
                                  ]);
                                }}
                                name="projectInvolves"
                                value="blockchain"
                              />
                            }
                            label="Blockchain / Crypto"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={projectInvolves.includes('sharepoint')}
                                onChange={e => {
                                  data.projectInvolves = xor(projectInvolves, [
                                    e.target.value,
                                  ]);
                                }}
                                name="projectInvolves"
                                value="sharepoint"
                              />
                            }
                            label="SharePoint"
                            classes={{ label: 'muiLabel' }}
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="columns field is-gapless">
                      <div className="column label">
                        What is your timeframe?
                      </div>
                      <div className="column">
                        <RadioGroup
                          name="timeframe"
                          value={timeframe}
                          onChange={this.handleChange('timeframe')}
                          classes={{ root: 'radioRoot' }}
                        >
                          <FormControlLabel
                            value="asap"
                            control={<Radio />}
                            label="ASAP"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            value="1 - 3 months"
                            control={<Radio />}
                            label="1 - 3 months"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            value="3 - 6 months"
                            control={<Radio />}
                            label="3 - 6 months"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            value="6 - 12 months"
                            control={<Radio />}
                            label="6 - 12 months"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            value="no timeframe"
                            control={<Radio />}
                            label="No timeframe"
                            classes={{ label: 'muiLabel' }}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <div className="columns field is-gapless">
                      <div className="column label">
                        What is your proposed budget?
                      </div>
                      <div className="column">
                        <RadioGroup
                          name="budget"
                          value={budget}
                          onChange={this.handleChange('budget')}
                          classes={{ root: 'radioRoot' }}
                        >
                          <FormControlLabel
                            value="5k-20k"
                            control={<Radio />}
                            label="$5k - 20k$"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            value="20k-50k"
                            control={<Radio />}
                            label="$20k - $50k"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            value="50k-200k"
                            control={<Radio />}
                            label="$50k - $200k"
                            classes={{ label: 'muiLabel' }}
                          />
                          <FormControlLabel
                            value="200k+"
                            control={<Radio />}
                            label="$200k+"
                            classes={{ label: 'muiLabel' }}
                          />
                        </RadioGroup>
                      </div>
                    </div>
                    <TextField
                      label="Tell us more about your project..."
                      fullWidth
                      margin={'normal'}
                      multiline
                      rows={8}
                      value={tellUsMore}
                      name="tellUsMore"
                      onChange={e => {
                        this.data.tellUsMore = e.target.value;
                      }}
                    />
                  </MuiThemeProvider>
                  <div className="btn-wrapper">
                    <button type="submit" disabled={!this.isFormValid()}>
                      Send message
                    </button>
                  </div>
                </form>
              </div>
            </Fragment>
          )}
        </div>
      </section>
    );
  }
}
