import React from 'react';

import Layout from '../../components/layout';
import './index.sass';
import SEO from '../../components/seo';
import ContactSection from "../../components/contact";

const Index = () => {

  return (
    <Layout pageType="blog">
      <SEO
        title="Node Vision | Contact"
      />
      <div className="contact-page">
        <section className="heading">
          <h1>Let's get started</h1>
        </section>
        <ContactSection />
      </div>
    </Layout>
  );
};

export default Index;